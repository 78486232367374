import AssetCapture from '^pages/assetCapture/assetCapture';
import Customer from '^pages/customer/customerList';
import Error from '^pages/error';
import Extension from '^pages/extension/extensionList';
import ExtensionEdit from '^pages/extension/extensionEditCode';
import ExtensionLaunch from '^pages/extension/extensionLaunch';
import Facilities from '^pages/facility/facilityList';
import HomeContainer from '^pages/home/homeContainer';
import Login from '^pages/login';
import Logout from '^pages/logout';
import Packing from '^pages/packing/packing';
import Part from '^pages/part/partList';
import PartConfig from '^pages/part/configList';
import Resources from '^pages/resource/resourceList';
import Setting from '^pages/setting/settingList';
import StagedWorkTicketList from '^pages/stagedWorkTicket/stagedWorkTicketList';
import StorageLocations from '^pages/storageLocation/storageLocationList';
import User from '^pages/user/userList';
import WorkTicketBOMList from '^pages/workTicket/workTicketBOMList';
import WorkTicketKitsList from '^pages/workTicket/kitting/workTicketKitsList';
import WorkTicketList from '^pages/workTicket/workTicketList';
import WorkTicketUnitOverview from '^pages/workTicket/unitOverview/workTicketUnitOverview';
import WorkTicketUnitsList from '^pages/workTicket/units/workTicketUnitsList';

const routesConfig = [
    {
        path: '*',
        element: <Error message={'Page Not Found'}/>,
    },
    {
        path: '/login',
        element: <Login/>,
        authentication: true,
    },
    {
        path: '/logout',
        element: <Logout/>,
        authentication: true,
    },
    {
        path: '/facilities',
        element: <Facilities/>,
    },
    {
        path: '/users',
        element: <User/>,
    },
    {
        path: '/customers',
        element: <Customer/>,
    },
    {
        path: '/parts',
        element: <Part/>,
    },
    {
        path: '/parts/:part_id/configs',
        element: <PartConfig/>,
    },
    {
        path: '/settings',
        element: <Setting/>,
    },
    {
        path: '/',
        element: <HomeContainer/>,
    },
    {
        path: '/resources',
        element: <Resources/>,
    },
    {
        path: '/storage-locations',
        element: <StorageLocations/>,
    },
    {
        path: '/staged-work-tickets',
        element: <StagedWorkTicketList/>,
    },
    {
        path: '/work-tickets',
        element: <WorkTicketList/>,
    },
    {
        path: '/work-tickets/:work_ticket_id/bom',
        element: <WorkTicketBOMList/>,
    },
    {
        path: '/work-tickets/:work_ticket_id/:config_id/units',
        element: <WorkTicketUnitsList/>,
    },
    {
        path: '/work-tickets/:work_ticket_id/unit-overview',
        element: <WorkTicketUnitOverview/>,
    },
    {
        path: '/work-tickets/:work_ticket_id/kits',
        element: <WorkTicketKitsList/>,
    },
    {
        path: '/asset-capture',
        element: <AssetCapture/>,
    },
    {
        path: '/packing',
        element: <Packing/>,
    },
    {
        path: '/manage-extensions',
        element: <Extension global={true}/>,
    },
    {
        path: '/manage-extensions/:extension_id',
        element: <ExtensionEdit/>,
    },
    {
        path: '/extensions',
        element: <Extension global={false}/>,
    },
    {
        path: '/extensions/:extension_id',
        element: <ExtensionLaunch/>,
    },
];

export default routesConfig;
