import React,
{useState, useEffect, useContext, useCallback} from 'react';
import {isResponseError} from '^utilities/isResponseError';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {
    filterUnits,
    getAssemblyUnits,
    getDescendants,
    getNonSerializedMap,
    getGroupedUnits,
    toggleAccordion,
    getBarcodeUnits,
} from '^pages/workTicket/utilities/kittingAssemblyHelper';
import CustomAccordion from '^common/customAccordion';
import EmptyCard from '^pages/workTicket/commonAssemblyKitting/emptyCard';
import SerializedCard
    from '^pages/workTicket/commonAssemblyKitting/serializedCard';
import NonSerializedCard
    from '^pages/workTicket/commonAssemblyKitting/nonSerializedCard';
import AsideHeader from '^common/asideHeader';
import {
    Accordion,
    Spinner,
} from 'react-bootstrap';
import {toast} from 'react-toastify';
import AssemblyKittingFilter
    from '^pages/workTicket/commonAssemblyKitting/assemblyKittingFilter';

const WorkTicketKitting = ({
    kit,
    updateKit,
    workTicketId,
}) => {
    const api = useContext(ApiContext);

    const [units, setUnits] = useState(null);

    const [partConfigFilter, setPartConfigFilter] = useState(null);
    const [partConfigOptions, setPartConfigOptions] = useState([]);

    const [serializedFilter, setSerializedFilter] = useState(null);
    const [nonSerializedFilter, setNonSerializedFilter] = useState(null);

    // For use in Filter
    const [barcodeUnits, setBarcodeUnits] = useState({
        combined: {},
        available: {},
    });
    const [barcodeFormData, setBarcodeFormData] = useState({
        combined: {
            non_serialized: {},
        },
        available: {
            non_serialized: {},
        },
    });

    const [formData, setFormData] = useState({
        combined: {
            non_serialized: {},
        },
        available: {
            non_serialized: {},
        },
    });

    const [descendantUnits, setDescendantUnits] = useState({
        combined: {},
        available: {},
    });

    const [descendantTrees, setDescendantTrees] = useState({
        combined: {},
        available: {},
    });

    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);

    const [existingKey, setExistingKey] = useState('0');
    const [availableKey, setAvailableKey] = useState('0');

    const fetchSkus = useCallback(async () => {
        setLoading(true);
        const response = await api.get(`/work-tickets/${workTicketId}/bom`);
        setLoading(false);
        if (isResponseError(response)) {
            toast.error('Failed to fetch SKUs from BOM');
            return;
        }
        const skus = response?.data ?? [];
        setPartConfigOptions(_.map(skus, (sku) => ({
            // eslint-disable-next-line max-len
            label: `${sku.part_number}-${sku.config_label} (${sku.serialized ? 'Serialized' : 'Non-Serialized'})`,
            value: sku,
        })));
    }, [api, setLoading, workTicketId]);

    useEffect(() => {
        setFirstLoad(false);
    }, []);

    const fetchUnits = useCallback(async () => {
        setLoading(true);
        let unitsList = [];

        const response = await api.get('/units', {
            params: {
                work_ticket_group_id: kit
                    ?.work_ticket_group?.work_ticket_group_id,
            },
            headers: {'X-Consistent-Read': true},
        });

        if (isResponseError(response)) {
            return;
        }

        unitsList.push(...response.data.results);

        if (kit.stage !== 'Rolled-Back') {
            unitsList = _.filter(unitsList,
                (unit) => unit.stage !== 'Rolled-Back');
        }

        const allUnitsGrouped
            = getGroupedUnits(false, null, unitsList);

        const nonKitted
            = filterUnits('kit', false, allUnitsGrouped, kit.kit_id);
        const kitted
            = filterUnits('kit', true, allUnitsGrouped, kit.kit_id);

        const barcodeNonKittedUnits = getBarcodeUnits(_.cloneDeep(nonKitted));
        const barcodeKittedUnits = getBarcodeUnits(_.cloneDeep(kitted));

        setBarcodeUnits({
            combined: _.cloneDeep(barcodeKittedUnits),
            available: _.cloneDeep(barcodeNonKittedUnits),
        });

        setUnits({
            combined: kitted,
            available: nonKitted,
        });
        setUpdate(false);
        setLoading(false);
    }, [api, kit]);

    useEffect(() => {
        if (_.isNil(partConfigFilter)
                && existingKey !== '0' && availableKey !== '0') {
            toggleAccordion(setExistingKey, '0');
            toggleAccordion(setAvailableKey, '0');
        }
    }, [partConfigFilter, existingKey, availableKey]);

    useEffect(() => {
        if (api && kit && firstLoad) {
            fetchUnits();
        } else if (api && update) {
            fetchUnits();
        }
    }, [api, kit, update, fetchUnits, firstLoad]);

    useEffect(() => {
        if (api && workTicketId) {
            fetchSkus();
        }
    }, [
        api,
        workTicketId,
        fetchSkus,
    ]);

    useEffect(() => {
        if (!_.isNil(units?.combined)) {
            getAssemblyUnits(units?.combined, api)
                .then((result) => {
                    setDescendantUnits((prevState) => ({
                        ...prevState,
                        combined: result,
                    }));
                })
                .catch((e) => console.error(e));
        }
    }, [api, units?.combined]);

    useEffect(() => {
        setBarcodeFormData({
            combined: {
                non_serialized:
                    getNonSerializedMap(true, barcodeUnits.combined),
            },
            available: {
                non_serialized:
                    getNonSerializedMap(false, barcodeUnits.available),
            },
        });
    }, [barcodeUnits]);

    useEffect(() => {
        setFormData({
            combined: {
                non_serialized: getNonSerializedMap(true, units?.combined),
            },
            available: {
                non_serialized: getNonSerializedMap(false, units?.available),
            },
        });
    }, [units, descendantUnits?.combined]);

    useEffect(() => {
        if (!_.isNil(descendantUnits?.combined)) {
            if (descendantUnits?.combined) {
                for (const [id, units] of Object.entries(
                    descendantUnits?.combined)) {
                    const descendants = getDescendants(units, null);
                    setDescendantTrees((prevState) => ({
                        ...prevState,
                        combined: {
                            ...prevState.combined,
                            [id]: {
                                ...prevState.combined[id],
                                children: descendants,
                            },
                        },
                    }));
                }
            }
        }
    }, [descendantUnits?.combined]);

    useEffect(() => {
        if (!_.isNil(descendantUnits?.available)) {
            if (descendantUnits?.available) {
                for (const [id, units] of Object.entries(
                    descendantUnits?.available)) {
                    const descendants = getDescendants(units, null);
                    setDescendantTrees((prevState) => ({
                        ...prevState,
                        available: {
                            ...prevState.available,
                            [id]: {
                                ...prevState.available[id],
                                children: descendants,
                            },
                        },
                    }));
                }
            }
        }
    }, [descendantUnits?.available]);

    const handleSubmit = useCallback(async (kitting, ids) => {
        if (ids) {
            setLoading(true);
            const response = await api.patch('/units', {
                unit_id: [
                    ...ids,
                ],
                kit: kitting
                    ? {kit_id: kit.kit_id}
                    : null,
            });

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
            }

            updateKit(_.set(
                kit,
                'unit_count',
                kitting
                    ? _.toInteger(kit?.unit_count) +1
                    : _.toInteger(kit?.unit_count) -1,
            ));

            setUpdate(true);
            setPartConfigFilter(null);
            setSerializedFilter(null);
            setNonSerializedFilter(null);
            toggleAccordion(setExistingKey, '0');
            toggleAccordion(setAvailableKey, '0');
        }
    }, [api, kit, updateKit]);

    /* eslint-disable max-len */
    return <>
        <AsideHeader>
            {`Add/Remove Units to Kit - ${kit.label}`}
        </AsideHeader>
        {!loading && <AssemblyKittingFilter
            unitId={null}
            units={units}
            barcodeUnits={barcodeUnits}
            setBarcodeUnits={setBarcodeUnits}
            setDescendantTrees={setDescendantTrees}
            workTicketId={workTicketId}
            asideData={kit}
            filterType={'kit'}
            filters={{
                partConfigFilter,
                serializedFilter,
                nonSerializedFilter,
            }}
            setters={{
                setPartConfigFilter,
                setSerializedFilter,
                setNonSerializedFilter,
                setExistingKey,
                setAvailableKey,
            }}
            formData={formData}
            setFormData={setFormData}
            barcodeFormData={barcodeFormData}
            setBarcodeFormData={setBarcodeFormData}
            descendantTrees={descendantTrees}
            handleSubmit={handleSubmit}
            partConfigOptions={partConfigOptions}
        />}
        {!loading ? <CustomAccordion key={'Existing'} activeKey={existingKey}>
            <Accordion.Item eventKey={'0'}>
                <Accordion.Header onClick={() => toggleAccordion(setExistingKey, '0')}>
                    {'Existing'}
                </Accordion.Header>
                <Accordion.Body>
                    {_.isEmpty(units?.combined) && <EmptyCard description={'No Units in Kit'}/>}
                    {units?.combined && Object.entries(units?.combined).map(([partNumber, serialized], index) => {
                        return (<>
                            <CustomAccordion key={`${partNumber}-accordion`} defaultActiveKey={partNumber}>
                                <Accordion.Item eventKey={partNumber}>
                                    <Accordion.Header>
                                        {partNumber}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {!_.includes(partNumber, 'Non-Serialized')
                                            ? _.map(serialized, (unit) =>
                                                <SerializedCard
                                                    unit={unit}
                                                    unitId={null}
                                                    descendantTrees={descendantTrees}
                                                    handleSubmit={handleSubmit}
                                                />)
                                            : _.map(serialized, (unitsList, key) =>
                                                <NonSerializedCard
                                                    combined={true}
                                                    label={key}
                                                    partConfig={partNumber}
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    units={unitsList}
                                                    handleSubmit={handleSubmit}
                                                />)
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </CustomAccordion>
                        </>);
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </CustomAccordion> : <Spinner/>}
        {!loading ? <CustomAccordion key={'Available'} activeKey={availableKey}>
            <Accordion.Item eventKey={'0'}>
                <Accordion.Header onClick={() => toggleAccordion(setAvailableKey, '0')}>
                    {'Available'}
                </Accordion.Header>
                <Accordion.Body>
                    {_.isEmpty(units?.available) && <EmptyCard description={'No Units Available to Add to Kit'}/>}
                    {units?.available && Object.entries(units?.available).map(([partNumber, serialized], index) => {
                        return (
                            <>
                                <CustomAccordion key={`${partNumber}-accordion`} defaultActiveKey={partNumber}>
                                    <Accordion.Item eventKey={partNumber}>
                                        <Accordion.Header>
                                            {partNumber}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {!_.includes(partNumber, 'Non-Serialized')
                                                ? _.map(serialized, (unit) =>
                                                    <SerializedCard
                                                        api={api}
                                                        setDescendantUnits={setDescendantUnits}
                                                        unit={unit}
                                                        unitId={null}
                                                        descendantTrees={descendantTrees}
                                                        handleSubmit={handleSubmit}
                                                    />)
                                                : _.map(serialized, (unitsList, key) => {
                                                    return <NonSerializedCard
                                                        combined={false}
                                                        label={key}
                                                        partConfig={partNumber}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        units={unitsList}
                                                        handleSubmit={handleSubmit}
                                                    />;
                                                })
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </CustomAccordion>
                            </>);
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </CustomAccordion> : null}
    </>;
    /* eslint-enable max-len */
};

export default WorkTicketKitting;
