import React,
{useState, useEffect, useContext, useCallback} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import AsideHeader from '^common/asideHeader';
import {
    Accordion,
    Spinner,
} from 'react-bootstrap';
import {
    filterUnits,
    getAssemblyUnits,
    getDescendants,
    getNonSerializedMap,
    getGroupedUnits,
    toggleAccordion,
    getDescendantNames,
    getBarcodeUnits,
} from '^pages/workTicket/utilities/kittingAssemblyHelper';
import CustomAccordion from '^common/customAccordion';
import EmptyCard from '^pages/workTicket/commonAssemblyKitting/emptyCard';
import SerializedCard
    from '^pages/workTicket/commonAssemblyKitting/serializedCard';
import NonSerializedCard
    from '^pages/workTicket/commonAssemblyKitting/nonSerializedCard';
import {toast} from 'react-toastify';
import AssemblyKittingFilter
    from '^pages/workTicket/commonAssemblyKitting/assemblyKittingFilter';
import UnitCard from '^pages/workTicket/commonAssemblyKitting/unitCard';

const WorkTicketAddRemoveComponents = ({
    unitId,
    unitData,
    unitsArray,
    updateAllUnits,
    updateUnitsState,
    workTicketId,
}) => {
    const api = useContext(ApiContext);

    const [units, setUnits] = useState(null);
    const [allUnits, setAllUnits] = useState(unitsArray);

    const [partConfigFilter, setPartConfigFilter] = useState(null);
    const [partConfigOptions, setPartConfigOptions] = useState([]);
    const [serializedFilter, setSerializedFilter] = useState(null);
    const [nonSerializedFilter, setNonSerializedFilter] = useState(null);

    const [formData, setFormData] = useState({
        combined: {
            non_serialized: {},
        },
        available: {
            non_serialized: {},
        },
    });

    const [descendantUnits, setDescendantUnits] = useState({
        combined: {},
        available: {},
    });
    const [descendantTrees, setDescendantTrees] = useState({
        combined: {},
        available: {},
    });

    // For use in Filter
    const [barcodeUnits, setBarcodeUnits] = useState({
        combined: {},
        available: {},
    });
    const [barcodeFormData, setBarcodeFormData] = useState({
        combined: {
            non_serialized: {},
        },
        available: {
            non_serialized: {},
        },
    });

    const [showParent, setShowParent] = useState(false);
    const [parentUnit, setParentUnit] = useState(null);
    const [parentDescendantNames, setParentDescendantNames] = useState(null);
    const [unitDescendantNames, setUnitDescendantNames] = useState(null);

    const [loading, setLoading] = useState(true);

    const [existingKey, setExistingKey] = useState('0');
    const [availableKey, setAvailableKey] = useState('0');

    const fetchSkus = useCallback(async () => {
        setLoading(true);
        const response = await api.get(`/work-tickets/${workTicketId}/bom`);
        setLoading(false);

        if (isResponseError(response)) {
            toast.error('Failed to fetch SKUs from BOM');
            return;
        }

        const skus = response?.data ?? [];

        setPartConfigOptions(_.map(skus, (sku) => ({
            // eslint-disable-next-line max-len
            label: `${sku.part_number}-${sku.config_label} (${sku.serialized ? 'Serialized' : 'Non-Serialized'})`,
            value: sku,
        })));
    }, [api, setLoading, workTicketId]);

    const fetchUnits = useCallback(async (id, unitsList) => {
        setLoading(true);
        const assembledUnits = await api.get(`/units/${id}/assembly`, {
            headers: {'X-Consistent-Read': true},
        });
        setLoading(false);

        if (isResponseError(assembledUnits)) {
            toast.error('Failed to fetch Unit assembly');
            return;
        }

        let unitsListCloned = _.cloneDeep(unitsList);

        setDescendantUnits((prevState) => ({
            ...prevState,
            combined: assembledUnits?.data,
        }));

        if (unitData.stage !== 'Rolled-Back') {
            unitsListCloned = _.filter(unitsListCloned,
                (unit) => unit.stage !== 'Rolled-Back');
        }

        const groupedAssembledUnits
            = getGroupedUnits(true, id, assembledUnits?.data);
        const groupedAllUnits
            = getGroupedUnits(false, null, unitsListCloned);
        const assembled
            = filterUnits('assembly', true, groupedAssembledUnits, id);
        const nonAssembled
            = filterUnits('assembly', false, groupedAllUnits, id,
                unitData?.assembled_into_unit_id);

        const parent = _.find(
            unitsList, {'unit_id': unitData.assembled_into_unit_id});
        setParentUnit(parent);

        setUnits({
            combined: assembled,
            available: nonAssembled,
            ...(unitData?.assembled_into_unit_id
                ? {parent: {
                    [`${parent?.sku ?? ''} Serialized`]:
                        [parent],
                }}
                : {}),
        });

        const clonedAssemble = _.cloneDeep(assembled);
        const clonedNonAssemble = _.cloneDeep(nonAssembled);

        const barcodeAssembledUnits = getBarcodeUnits(clonedAssemble);
        const barcodeNonAssembledUnits = getBarcodeUnits(clonedNonAssemble);

        setBarcodeUnits({
            combined: _.cloneDeep(barcodeAssembledUnits),
            available: _.cloneDeep(barcodeNonAssembledUnits),
        });
    }, [api, setLoading, unitData]);

    useEffect(() => {
        if (_.isNil(partConfigFilter)
            && existingKey !== '0' && availableKey !== '0') {
            toggleAccordion(setExistingKey, '0');
            toggleAccordion(setAvailableKey, '0');
        }
    }, [partConfigFilter, existingKey, availableKey]);

    useEffect(() => {
        if (api && workTicketId) {
            fetchSkus();
        }
    }, [
        api,
        workTicketId,
        fetchSkus,
    ]);

    useEffect(() => {
        if (api && allUnits && unitId) {
            fetchUnits(unitId, allUnits);
        }
    }, [
        api,
        unitId,
        unitData?.work_ticket_id,
        fetchUnits,
        allUnits,
    ]);

    useEffect(() => {
        setBarcodeFormData({
            combined: {
                non_serialized:
                    getNonSerializedMap(true, barcodeUnits.combined),
            },
            available: {
                non_serialized:
                    getNonSerializedMap(false, barcodeUnits.available),
            },
        });
    }, [barcodeUnits]);

    useEffect(() => {
        setFormData({
            combined: {
                non_serialized: getNonSerializedMap(true, units?.combined),
            },
            available: {
                non_serialized: getNonSerializedMap(false, units?.available),
            },
        });
    }, [units, descendantUnits?.combined]);

    useEffect(() => {
        if (!_.isNil(descendantUnits?.combined)) {
            setDescendantTrees((prevState) => ({
                ...prevState,
                combined: getDescendants(descendantUnits.combined, null),
            }));
        }
    }, [descendantUnits?.combined]);

    useEffect(() => {
        if (descendantUnits?.available) {
            for (const [id, units] of Object.entries(
                descendantUnits?.available)) {
                const descendants = getDescendants(units, id);
                const unitIndex = _.findIndex(units, {'unit_id': id});
                setDescendantTrees((prevState) => ({
                    ...prevState,
                    available: {
                        ...prevState?.available,
                        [id]: {
                            children: descendants,
                            name: unitIndex >= 0
                                ? units[unitIndex]?.serial_number
                                : null,
                        },
                    },
                }));
            }
        }
    }, [descendantUnits?.available]);

    useEffect(() => {
        if (units?.parent && api) {
            getAssemblyUnits(units?.parent, api)
                .then((result) => {
                    setDescendantUnits((prevState) => ({
                        ...prevState,
                        parent: result,
                    }));
                })
                .catch((e) => console.error(e));
        }
    }, [units?.parent, api]);

    useEffect(() => {
        if (descendantUnits?.parent) {
            for (const [id, units] of Object.entries(
                descendantUnits?.parent)) {
                const descendants = getDescendants(units, id);
                const unitIndex = _.findIndex(units, {'unit_id': id});
                setDescendantTrees((prevState) => ({
                    ...prevState,
                    parent: {
                        ...prevState?.parent,
                        [id]: {
                            children: descendants,
                            name: unitIndex >= 0
                                ? units[unitIndex]?.serial_number
                                : null,
                        },
                    },
                }));
            }
        }
    }, [descendantUnits?.parent]);

    useEffect(() => {
        if (!_.isEmpty(descendantTrees?.parent && units?.parent && unitId)) {
            const key = Object.keys(units?.parent)[0];
            setParentDescendantNames(
                getDescendantNames(
                    // eslint-disable-next-line
                    descendantTrees?.parent[units?.parent[key][0].unit_id]?.children),
            );
            setUnitDescendantNames(
                getDescendantNames(
                    // eslint-disable-next-line
                    descendantTrees?.parent[units?.parent[key][0].unit_id]?.children[unitId]?.children),
            );
        }
    }, [descendantTrees?.parent, units?.parent, unitId]);

    useEffect(() => {
        if (!_.isEmpty(descendantTrees?.combined && unitId)) {
            setUnitDescendantNames(
                getDescendantNames(
                    descendantTrees?.combined[unitId]?.children),
            );
        }
    }, [descendantTrees?.combined, unitId]);

    const handleUpdateUnits = useCallback((unit) => {
        setAllUnits((prevState) => (
            updateUnitsState(prevState, unit, false)
        ));

        updateAllUnits(unit);
    }, [updateAllUnits, updateUnitsState]);

    const handleSubmit = async (assemble, ids, nonSerializedUnits=false) => {
        if (ids) {
            setLoading(true);
            const response = await api.patch('/units', {
                unit_id: [
                    ...ids,
                ],
                assembled_into_unit_id: assemble
                    ? unitId
                    : null,
            });

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
            }

            if (!nonSerializedUnits) {
                handleUpdateUnits(response?.data[0]);
            } else {
                const unitsResponse = response?.data;
                for (const unit of unitsResponse) {
                    handleUpdateUnits(unit);
                }
            }

            setPartConfigFilter(null);
            setSerializedFilter(null);
            setNonSerializedFilter(null);
            toggleAccordion(setExistingKey, '0');
            toggleAccordion(setAvailableKey, '0');
        }
    };

    /* eslint-disable max-len */
    return <>
        <AsideHeader>
            {`Add/Remove Components: ${unitData.serial_number}`}
        </AsideHeader>
        {!loading && showParent && parentUnit && <UnitCard
            unit={parentUnit}
            originalUnitId={unitId}
            descendantTrees={descendantTrees}
            unitDescendantNames={parentDescendantNames}
            subComponent={parentUnit}
            handleSubmit={() => setShowParent((prevState) => !prevState)}
        />}
        {!loading && !showParent && parentUnit && <UnitCard
            unit={unitData}
            originalUnitId={unitId}
            descendantTrees={descendantTrees}
            unitDescendantNames={unitDescendantNames}
            subComponent={parentUnit}
            handleSubmit={() => setShowParent((prevState) => !prevState)}
        />}
        {!loading && !showParent && !parentUnit && <UnitCard
            unit={unitData}
            originalUnitId={unitId}
            descendantTrees={descendantTrees}
            unitDescendantNames={unitDescendantNames}
            currentComponent={unitData}
        />}
        {!loading && <AssemblyKittingFilter
            unitId={unitId}
            asideData={unitData}
            units={units}
            parentUnit={parentUnit}
            barcodeUnits={barcodeUnits}
            setBarcodeUnits={setBarcodeUnits}
            filterType={'assembly'}
            filters={{
                partConfigFilter,
                serializedFilter,
                nonSerializedFilter,
            }}
            setters={{
                setPartConfigFilter,
                setSerializedFilter,
                setNonSerializedFilter,
                setExistingKey,
                setAvailableKey,
            }}
            formData={formData}
            setDescendantTrees={setDescendantTrees}
            setFormData={setFormData}
            barcodeFormData={barcodeFormData}
            setBarcodeFormData={setBarcodeFormData}
            descendantTrees={descendantTrees}
            handleSubmit={handleSubmit}
            partConfigOptions={partConfigOptions}
            workTicketId={workTicketId}
        />}
        {!loading ? <CustomAccordion key={'Existing'}
            activeKey={existingKey}
        >
            <Accordion.Item eventKey={'0'}>
                <Accordion.Header onClick={() => toggleAccordion(setExistingKey, '0')}>
                    {'Existing'}
                </Accordion.Header>
                <Accordion.Body>
                    {_.isEmpty(units?.combined) && <EmptyCard description={'No Subcomponents Attached'}/>}
                    {units?.combined && Object.entries(units?.combined).map(([partNumber, serialized], index) => {
                        return (
                            <>
                                <CustomAccordion key={`${partNumber}-accordion`} defaultActiveKey={partNumber}>
                                    <Accordion.Item eventKey={partNumber}>
                                        <Accordion.Header>
                                            {partNumber}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {!_.includes(partNumber, 'Non-Serialized')
                                                ? _.map(serialized, (unit) =>
                                                    <SerializedCard
                                                        unit={unit}
                                                        unitId={unitId}
                                                        descendantTrees={descendantTrees}
                                                        handleSubmit={handleSubmit}
                                                    />)
                                                : _.map(serialized, (unitsList, key) =>
                                                    <NonSerializedCard
                                                        combined={true}
                                                        label={key}
                                                        partConfig={partNumber}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        units={unitsList}
                                                        handleSubmit={handleSubmit}
                                                    />)
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </CustomAccordion>
                            </>);
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </CustomAccordion>
            : <Spinner/>}
        {!loading ? <CustomAccordion key={'Available'}
            activeKey={availableKey}
        >
            <Accordion.Item eventKey={'0'} active={true}>
                <Accordion.Header onClick={() => toggleAccordion(setAvailableKey, '0')}>
                    {'Available'}
                </Accordion.Header>
                <Accordion.Body>
                    {_.isEmpty(units?.available) && <EmptyCard description={'No Subcomponents Available to Add to Unit'}/>}
                    {units?.available && Object.entries(units?.available).map(([partNumber, serialized], index) => {
                        return (
                            <>
                                <CustomAccordion key={`${partNumber}-accordion`} defaultActiveKey={partNumber}>
                                    <Accordion.Item eventKey={partNumber}>
                                        <Accordion.Header>
                                            {partNumber}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {!_.includes(partNumber, 'Non-Serialized')
                                                ? _.map(serialized, (unit) =>
                                                    <SerializedCard
                                                        api={api}
                                                        unit={unit}
                                                        unitId={unitId}
                                                        descendantTrees={descendantTrees}
                                                        setDescendantUnits={setDescendantUnits}
                                                        handleSubmit={handleSubmit}
                                                    />)
                                                : _.map(serialized, (unitsList, key) =>
                                                    <NonSerializedCard
                                                        combined={false}
                                                        label={key}
                                                        partConfig={partNumber}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        units={unitsList}
                                                        handleSubmit={handleSubmit}
                                                    />)
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </CustomAccordion>
                            </>);
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </CustomAccordion> : null}
    </>;
    /* eslint-enable max-len */
};

export default WorkTicketAddRemoveComponents;
