import React,
{useCallback, useEffect, useRef, useState, useContext} from 'react';
import _ from 'lodash';
import CustomAccordion from '^common/customAccordion';
import {Accordion, Form} from 'react-bootstrap';
import {FormSelect, AsyncFormSelect} from '^common/formSelect';
import SerializedCard
    from '^pages/workTicket/commonAssemblyKitting/serializedCard';
import NonSerializedCard
    from '^pages/workTicket/commonAssemblyKitting/nonSerializedCard';
import {
    getNonSerializedMap,
    toggleAccordion,
    getBarcodeUnits,
    getGroupedUnits,
    filterUnits,
    getDescendants,
} from '^pages/workTicket/utilities/kittingAssemblyHelper';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';

const AssemblyKittingFilter = ({
    unitId,
    units,
    asideData,
    parentUnit,
    filterType,
    filters,
    setters,
    formData,
    setFormData,
    barcodeUnits,
    setBarcodeUnits,
    barcodeFormData,
    setBarcodeFormData,
    descendantTrees,
    setDescendantTrees,
    handleSubmit,
    partConfigOptions,
    workTicketId,
}) => {
    const api = useContext(ApiContext);
    const skuInputRef = useRef(null);
    const [unitsCloned, setUnitsCloned] = useState([]);

    const [partConfigInput, setPartConfigInput] = useState('');
    const [barcodeType, setBarcodeType] = useState(null);

    const [serializedOptions, setSerializedOptions] = useState([]);
    const [nonSerializedOptions, setNonSerializedOptions] = useState([]);
    const [loadingNonSerializedOptions,
        setLoadingNonSerializedOptions] = useState(false);

    const [filteredAssembledUnits, setFilteredAssembledUnits] = useState([]);
    const [filteredAvailableUnits, setFilteredAvailableUnits] = useState([]);

    const {partConfigFilter, serializedFilter, nonSerializedFilter} = filters;
    const {
        setPartConfigFilter,
        setSerializedFilter,
        setNonSerializedFilter,
        setExistingKey,
        setAvailableKey,
    } = setters;

    const focusInput = () => {
        if (skuInputRef.current) {
            skuInputRef.current.focus();
        }
    };

    const setupUnitsForFilter = useCallback(
        (units, stage, unitId, parentId) => {
            let unitsListCloned = _.cloneDeep(units);
            if (stage !== 'Rolled-Back') {
                unitsListCloned = _.filter(unitsListCloned,
                    (unit) => unit.stage !== 'Rolled-Back');
            }

            const groupedAllUnits
                = getGroupedUnits(false, null, unitsListCloned);
            const nonAssembled
                = filterUnits(filterType, false, groupedAllUnits, unitId,
                    parentId);

            const clonedNonAssemble = _.cloneDeep(nonAssembled);
            setUnitsCloned((prevState) => ({
                ...prevState,
                available: {
                    ...prevState.available,
                    ...clonedNonAssemble,
                },
                ...(parentId && parentUnit
                    ? {parentUnit: {
                        [`${parentUnit?.sku ?? ''} Serialized`]:
                            [parentUnit],
                    }}
                    : {}),
            }));

            const barcodeNonAssembledUnits = getBarcodeUnits(clonedNonAssemble);
            const clonedBarcodeNonAssembledUnits
                = _.cloneDeep(barcodeNonAssembledUnits);

            setBarcodeUnits((prevState) => ({
                ...prevState,
                available: {
                    ...prevState.available,
                    ...clonedBarcodeNonAssembledUnits,
                },
            }));
        }, [filterType, setBarcodeUnits, setUnitsCloned, parentUnit]);

    const searchForNonSerializedUnits = useCallback(async () => {
        if (!api || _.isNil(partConfigFilter)) return [];

        const allUnits = [];
        let nextOffset = null;

        try {
            setLoadingNonSerializedOptions(true);
            do {
                const response = await api.get('/units', {
                    params: {
                        work_ticket_id: workTicketId,
                        config_id: partConfigFilter.value.config_id,
                        offset: nextOffset,
                    },
                });

                if (isResponseError(response)) {
                    return [];
                }
                const unitsList = response?.data?.results ?? [];
                allUnits.push(...unitsList);
                nextOffset = response?.data?.next_offset;
            } while (nextOffset !== null);
        } catch (error) {
            console.error('Error fetching units:', error);
            return [];
        } finally {
            setLoadingNonSerializedOptions(false);
        }

        setupUnitsForFilter(
            allUnits,
            asideData.stage,
            unitId,
            asideData?.assembled_into_unit_id || asideData?.kit_id,
        );
    // eslint-disable-next-line max-len
    }, [partConfigFilter, api, asideData, unitId, setupUnitsForFilter, workTicketId]);

    useEffect(() => {
        if (partConfigFilter
            && _.includes(partConfigFilter?.label, 'Non-Serialized')) {
            searchForNonSerializedUnits();
        }
    }, [partConfigFilter, searchForNonSerializedUnits]);

    const searchForSerializedUnits = useCallback(async (serial) => {
        if (!api || _.isNil(partConfigFilter)) return [];

        const response = await api.get('/units', {
            params: {
                work_ticket_id: workTicketId,
                config_id: partConfigFilter.value.config_id,
                ...(serial ? {serial_number: serial} : {}),
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const unitsList = response?.data?.results ?? [];

        setupUnitsForFilter(
            unitsList,
            asideData.stage,
            unitId,
            asideData?.assembled_into_unit_id || asideData?.kit_id,
        );

        return _.map(unitsList,
            (unit) => ({label: unit.serial_number, value: unit}));
    }, [
        partConfigFilter,
        api,
        asideData,
        unitId,
        setupUnitsForFilter,
        workTicketId,
    ]);

    const getSerializedDescendantTree = useCallback(async (unitId) => {
        if (!api) return;

        const response = await api.get(`/units/${unitId}/assembly`);

        if (isResponseError(response)) {
            return;
        }

        const unitsList = response?.data ?? [];

        const descendants = getDescendants(unitsList, unitId);
        const unitIndex = _.findIndex(unitsList, {'unit_id': unitId});

        setDescendantTrees((prevState) => ({
            ...prevState,
            available: {
                ...prevState?.available,
                [unitId]: {
                    children: descendants,
                    name: unitIndex >= 0
                        ? unitsList[unitIndex]?.serial_number
                        : null,
                },
            },
        }));
    }, [api, setDescendantTrees]);

    useEffect(() => {
        if (!_.isNil(units)) {
            const unitsCombined = _.cloneDeep(units.combined);
            const unitsAvailable = _.cloneDeep(units.available);
            setUnitsCloned((prevState) => ({
                combined: {
                    ...prevState.combined,
                    ...unitsCombined,
                },
                available: {
                    ...prevState.available,
                    ...unitsAvailable,
                },
            }));
        }
    }, [units]);

    useEffect(() => {
        setBarcodeFormData({
            combined: {
                non_serialized:
                    getNonSerializedMap(true, barcodeUnits.combined),
            },
            available: {
                non_serialized:
                    getNonSerializedMap(false, barcodeUnits.available),
            },
        });
    }, [barcodeUnits, setBarcodeFormData]);

    useEffect(() => {
        setFormData({
            combined: {
                non_serialized:
                    getNonSerializedMap(true, unitsCloned?.combined),
            },
            available: {
                non_serialized:
                    getNonSerializedMap(false, unitsCloned?.available),
            },
        });
    }, [unitsCloned, setFormData]);

    useEffect(() => {
        if (partConfigOptions) {
            focusInput();
        }
    }, [partConfigOptions]);

    useEffect(() => {
        if (_.includes(partConfigFilter?.label, 'Non-Serialized')
            || barcodeType === 'Non-Serialized'
        ) {
            if (nonSerializedOptions.length === 1) {
                setNonSerializedFilter(nonSerializedOptions[0].value);
                if (serializedFilter) {
                    setSerializedFilter(null);
                }
                toggleAccordion(setExistingKey, null);
                toggleAccordion(setAvailableKey, null);
            }
        }
    }, [
        partConfigFilter,
        nonSerializedOptions,
        setNonSerializedFilter,
        serializedFilter,
        setSerializedFilter,
        setExistingKey,
        setAvailableKey,
        barcodeType,
    ]);

    useEffect(() => {
        const setOptions = (units, isSerialized, partConfig) => {
            if (isSerialized) {
                const availableUnits = units.available[partConfig];
                const assembledUnits = units.combined[partConfig];

                const filteredUnits
                    = [...(availableUnits ?? []), ...(assembledUnits ?? [])];
                setSerializedOptions(_.map(filteredUnits, (unit) => ({
                    label: unit.serial_number,
                    value: {...unit},
                })));
            } else {
                const availableOptions = units.available[partConfig];
                const assembledOptions = units.combined[partConfig];
                const availableKeys = availableOptions
                    ? Object.keys(availableOptions) || []
                    : [];
                const assembledKeys = assembledOptions
                    ? Object.keys(assembledOptions) || []
                    : [];
                const options
                    = [...new Set([...availableKeys, ...assembledKeys])];
                setNonSerializedOptions(_.map(options, (option) => ({
                    label: option,
                    value: {
                        label: option,
                        part_config: partConfig,
                    },
                })));
            }
        };

        // eslint-disable-next-line max-len
        if (unitsCloned?.combined && unitsCloned?.available && partConfigFilter?.label) {
            const isSerialized = _.includes(
                partConfigFilter?.label,
                '(Serialized)');
            const isNonSerialized = _.includes(partConfigFilter?.label,
                '(Non-Serialized)');

            if (isSerialized || isNonSerialized) {
                setOptions(unitsCloned, isSerialized, partConfigFilter?.label);
            } else {
                if (_.isArray(barcodeUnits.available[partConfigFilter?.label])
                    || _.isArray(barcodeUnits.combined[partConfigFilter?.label])
                ) {
                    setOptions(barcodeUnits, true, partConfigFilter?.label);
                } else {
                    setBarcodeType('Non-Serialized');
                    setOptions(barcodeUnits, false, partConfigFilter?.label);
                }
            }
        }
    }, [unitsCloned, barcodeUnits, partConfigFilter]);

    useEffect(() => {
        const getFilteredUnits = (unitsObj, partConfig, label) => {
            if (!unitsObj || !partConfig || !label) return [];
            const unitsForPartConfig = unitsObj[partConfig];
            return unitsForPartConfig ? unitsForPartConfig[label] : [];
        };

        if (nonSerializedFilter) {
            const {part_config: partConfig, label} = nonSerializedFilter;

            if (unitsCloned?.combined) {
                setFilteredAssembledUnits(
                    getFilteredUnits(unitsCloned.combined, partConfig, label),
                );
            }
            if (unitsCloned?.available) {
                setFilteredAvailableUnits(
                    getFilteredUnits(unitsCloned.available, partConfig, label),
                );
            }
            if (!_.includes(partConfig, 'Non-Serialized')
                && !_.isEmpty(barcodeUnits)) {
                if (!_.isEmpty(barcodeUnits.combined)) {
                    setFilteredAssembledUnits(
                        getFilteredUnits(
                            barcodeUnits.combined,
                            partConfig,
                            label,
                        ),
                    );
                }
                if (!_.isEmpty(barcodeUnits.available)) {
                    setFilteredAvailableUnits(
                        getFilteredUnits(
                            barcodeUnits.available,
                            partConfig,
                            label,
                        ),
                    );
                }
            }
        }
    }, [nonSerializedFilter, unitsCloned, barcodeUnits]);

    const filteredPartConfigOptions = _.filter(
        partConfigOptions,
        ({label}) => {
            const partInputRegExp = _.escapeRegExp(partConfigInput);
            const regex = new RegExp(`^${partInputRegExp}(?:$| .+)`);

            return partConfigInput
                ? regex.test(label)
                : false;
        },
    );

    return (<CustomAccordion key={'New Filter'}
        defaultActiveKey={'0'}>
        <Accordion.Item eventKey={'0'}>
            <Accordion.Header>{'New Filter'}</Accordion.Header>
            <Accordion.Body>
                <Form.Group>
                    <Form.Text>{'SKU'}</Form.Text>
                    <FormSelect
                        ref={skuInputRef}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        value={_.find(partConfigOptions,
                            {label: partConfigFilter?.label})
                            ?? null}
                        options={filteredPartConfigOptions}
                        onChange={(e) => {
                            setPartConfigFilter(e ?? null);
                            setSerializedFilter(null);
                            setNonSerializedFilter(null);
                            setBarcodeType(null);
                        }}
                        onInputChange={setPartConfigInput}
                        noOptionsMessage={({inputValue}) => inputValue
                            ? `SKU "${inputValue}" not found`
                            : 'Enter SKU'}
                    />
                    {partConfigFilter
                        && ((_.includes(partConfigFilter?.label,
                            'Non-Serialized')
                                || barcodeType === 'Non-Serialized')
                            ? <>
                                <Form.Text>{'Non-Serialized Units'}</Form.Text>
                                <FormSelect
                                    isDisabled={loadingNonSerializedOptions}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    value={_.find(nonSerializedOptions,
                                        {label: nonSerializedFilter?.label})
                                        ?? null}
                                    options={nonSerializedOptions}
                                    onChange={(e) => {
                                        setNonSerializedFilter(e?.value
                                            ?? null);
                                        if (serializedFilter) {
                                            setSerializedFilter(null);
                                        }
                                        toggleAccordion(setExistingKey, null);
                                        toggleAccordion(setAvailableKey, null);
                                    }}
                                />
                            </>
                            : <>
                                <Form.Text>{'Serial Number'}</Form.Text>
                                <AsyncFormSelect
                                    isClearable={true}
                                    loadOptions={searchForSerializedUnits}
                                    backspaceRemovesValue={true}
                                    value={_.find(serializedOptions, (option) =>
                                        option?.value?.serial_number
                                            === serializedFilter?.serial_number)
                                        ?? null
                                    }
                                    onChange={(e) => {
                                        if (e?.value?.has_assembly_components) {
                                            getSerializedDescendantTree(
                                                e.value.unit_id,
                                            );
                                        }
                                        setSerializedFilter(e?.value ?? null);
                                        if (nonSerializedFilter) {
                                            setNonSerializedFilter(null);
                                        }
                                        toggleAccordion(setExistingKey, null);
                                        toggleAccordion(setAvailableKey, null);
                                    }}
                                />
                            </>
                        )}
                </Form.Group>
                {!_.isNil(serializedFilter)
                    && <SerializedCard
                        unit={serializedFilter}
                        unitId={unitId}
                        descendantTrees={descendantTrees}
                        handleSubmit={handleSubmit}
                        kittingFilterCard={filterType === 'kit'}
                    />
                }
                {!_.isNil(nonSerializedFilter)
                    && <NonSerializedCard
                        combined={true}
                        label={nonSerializedFilter?.label}
                        partConfig={nonSerializedFilter?.part_config}
                        formData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? formData
                                : barcodeFormData
                        }
                        setFormData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? setFormData
                                : setBarcodeFormData
                        }
                        units={filteredAssembledUnits}
                        handleSubmit={handleSubmit}
                    />
                }
                {!_.isNil(nonSerializedFilter)
                    && <NonSerializedCard
                        combined={false}
                        label={nonSerializedFilter?.label}
                        partConfig={nonSerializedFilter?.part_config}
                        formData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? formData
                                : barcodeFormData
                        }
                        setFormData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? setFormData
                                : setBarcodeFormData
                        }
                        units={filteredAvailableUnits}
                        handleSubmit={handleSubmit}
                    />
                }
            </Accordion.Body>
        </Accordion.Item>
    </CustomAccordion>);
};

export default AssemblyKittingFilter;
