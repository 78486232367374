import React, {useContext, useState, useEffect, useCallback} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {targetMetricsStatusOptions} from '^config/targetMetrics';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import {DateTime} from 'luxon';

const customerToOption = (customer) => ({
    label: customer?.label,
    value: customer?.customer_id,
});

const storageLocationToOption = (storageLocation) => ({
    label: storageLocation?.label,
    value: storageLocation?.storage_location_id,
});

const MyAssignmentsFilter = ({
    filterParams,
    setFilterParams,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [userFacility] = useLocalStorage('facility_id', '');

    const [
        customer,
        setCustomer,
    ] = useState(filterParams?.customer ?? null);

    const [
        status,
        setStatus,
    ] = useState(filterParams?.status ?? null);

    const [
        minStage,
        setMinStage,
    ] = useState(filterParams?.minStage ?? null);

    const [
        maxStage,
        setMaxStage,
    ] = useState(filterParams?.maxStage ?? null);

    const [
        inStorage,
        setInStorage,
    ] = useState(filterParams?.inStorage ?? null);

    const [
        outStorage,
        setOutStorage,
    ] = useState(filterParams?.outStorage ?? null);

    const [
        targetMetricsStatus,
        setTargetMetricsStatus,
    ] = useState(filterParams?.targetMetricsStatus ?? null);

    const [
        priority,
        setPriority,
    ] = useState(filterParams?.priority ?? null);

    const [
        project,
        setProject,
    ] = useState(filterParams?.project ?? null);

    const [
        pool,
        setPool,
    ] = useState(filterParams?.pool ?? null);

    const [
        statusUpdatedAfter,
        setStatusUpdatedAfter,
    ] = useState(filterParams?.statusUpdatedAfter ?? '');

    const [
        shipDate,
        setShipDate,
    ] = useState(filterParams?.shipDate ?? '');

    const [customers, setCustomers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [stages, setStages] = useState([]);
    const [storageLocations, setStorageLocations] = useState([]);
    const [pools, setPools] = useState([]);

    const searchCustomers = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/customers', {
            params: label ? {label} : {},
        });

        if (isResponseError(response)) {
            return [];
        }

        const customers = response?.data?.results ?? [];

        setCustomers((prevState) => _.uniqBy(
            [...prevState, ...customers],
            'customer_id',
        ));

        return _.map(customers, customerToOption);
    }, [api]);

    const searchStorageLocations = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        if (!userFacility) {
            return [];
        }

        const response = await api.get('/storage-locations', {
            params: {
                active: 1,
                facility_id: userFacility,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const storageLocations = response?.data?.results ?? [];

        setStorageLocations((prevState) => _.uniqBy(
            [...prevState, ...storageLocations],
            'storage_location_id',
        ));

        return _.map(storageLocations, storageLocationToOption);
    }, [api, userFacility]);

    useEffect(() => {
        const fetchStatuses = async () => {
            const response = await api.get('/types/status');

            if (isResponseError(response)) {
                return;
            }

            setStatuses(response?.data);
        };

        if (api) {
            fetchStatuses();
        }
    }, [api]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(response?.data);
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    useEffect(() => {
        const fetchPools = async () => {
            const response = await api.get('/types/pool');

            if (isResponseError(response)) {
                return;
            }

            setPools(response?.data);
        };

        if (api) {
            fetchPools();
        }
    }, [api]);

    const resetFields = () => {
        const defaultWorkTicketParams = defaultSearchParamsObj?.workTicket;
        setCustomer(null);
        setStatus(defaultWorkTicketParams?.status ?? null);
        setMinStage(defaultWorkTicketParams?.min_stage ?? null);
        setMaxStage(defaultWorkTicketParams?.max_stage ?? null);
        setTargetMetricsStatus(null);
        setInStorage(null);
        setOutStorage(null);
        setPriority(null);
        setProject(null);
        setPool(null);
        setShipDate('');
        setStatusUpdatedAfter('');
    };

    const formSubmit = () => {
        setFilterParams({
            ...customer ? {customer} : {},
            ..._.size(status) ? {status} : {},
            ...minStage ? {minStage} : {},
            ...maxStage ? {maxStage} : {},
            ..._.size(targetMetricsStatus) ? {targetMetricsStatus} : {},
            ...inStorage ? {inStorage} : {},
            ...outStorage ? {outStorage} : {},
            ...priority ? {priority} : {},
            ...project ? {project} : {},
            ..._.size(pool) ? {pool} : {},
            ...shipDate ? {shipDate} : {},
            ...statusUpdatedAfter ? {statusUpdatedAfter} : {},
        });

        setAsideChildren(null);
    };

    const customerOptions = _.chain(customers)
        .map(customerToOption)
        .concat(customer)
        .compact()
        .uniqBy('value')
        .value();

    const statusOptions = _.map(statuses, (status) => ({
        label: status,
        value: status,
    }));

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    const poolOptions = _.map(pools, (pool) => ({
        label: pool ?? 'N/A',
        value: pool,
    }));

    const storageLocationOptions = _.chain(storageLocations)
        .map(storageLocationToOption)
        .concat(inStorage)
        .concat(outStorage)
        .compact()
        .uniqBy('value')
        .value();

    const boolOptions = [
        {
            label: 'Yes',
            value: '1',
        },
        {
            label: 'No',
            value: '0',
        },
    ];

    return <>
        <AsideHeader>
            <b>{'Filter Work'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Customer'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchCustomers}
                    value={_.find(
                        customerOptions,
                        {value: customer?.value},
                    ) ?? null}
                    onChange={setCustomer}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Status'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        statusOptions,
                        ({value}) => _.includes(status, value),
                    )}
                    options={statusOptions}
                    onChange={(e) => setStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Min Stage'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(stageOptions, {value: minStage}) ?? null}
                    options={stageOptions}
                    onChange={(e) => setMinStage(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Max Stage'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(stageOptions, {value: maxStage}) ?? null}
                    options={stageOptions}
                    onChange={(e) => setMaxStage(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Target Metrics Status'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        targetMetricsStatusOptions,
                        ({value}) => _.includes(targetMetricsStatus, value),
                    )}
                    options={targetMetricsStatusOptions}
                    onChange={(e) => setTargetMetricsStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'In Storage'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchStorageLocations}
                    value={_.find(
                        storageLocationOptions,
                        {value: inStorage?.value},
                    ) ?? null}
                    onChange={setInStorage}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Out Storage'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchStorageLocations}
                    value={_.find(
                        storageLocationOptions,
                        {value: outStorage?.value},
                    ) ?? null}
                    onChange={setOutStorage}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Priority'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: priority}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setPriority(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Project'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: project}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setProject(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Pool'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        poolOptions,
                        ({value}) => _.includes(pool, value),
                    )}
                    options={poolOptions}
                    onChange={(e) => setPool(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Status Updated After'}</Form.Text>
                <Form.Control
                    type={'datetime-local'}
                    value={DateTime
                        .fromISO(statusUpdatedAfter)
                        .toLocal()
                        .toISO({
                            includeOffset: false,
                            suppressSeconds: true,
                            suppressMilliseconds: true,
                        }) ?? ''}
                    onChange={(e) => setStatusUpdatedAfter(DateTime
                        .fromISO(e.target.value)
                        .toUTC()
                        .toISO())}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Ship Date'}</Form.Text>
                <Form.Control
                    type={'date'}
                    value={shipDate}
                    onChange={(e) => setShipDate(e?.target?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default MyAssignmentsFilter;
