import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {DropdownToggle} from '^common/dropdownToggle';
import {PageHeader} from '^common/pageHeader';
import {PageActionFilter} from '^common/pageAction';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableRow} from '^common/tableRow';
import UnitEdit from '^pages/workTicket/units/unitEdit';
import {Dropdown, Breadcrumb, Form, Spinner} from 'react-bootstrap';
import WorkTicketAddToKit from '^pages/workTicket/kitting/workTicketAddToKit';
import WorkTicketUnitAside from '^pages/workTicket/aside/workTicketUnitAside';
import WorkTicketAddRemoveComponents
    from '^pages/workTicket/assembly/workTicketAddRemoveComponents';
import WorkTicketAddToAssembly
    from '^pages/workTicket/assembly/workTicketAddToAssembly';
import {defaultSearchParams} from '^config/defaultSearchParams';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';
import UnitFilter from './unitFilter';

const WorkTicketUnitsList = () => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const {setAsideChildren} = useContext(AppContext);
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const [searchParams] = useSearchParams();

    const {work_ticket_id: workTicketId} = useParams();
    const {config_id: configId} = useParams();
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    const fetchUnits = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/units', {
            params: {
                limit,
                offset,
                work_ticket_id: workTicketId,
                config_id: configId,
                ...paramObject,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setUnits((prevState) => _.uniqBy(
            [...prevState, ...results],
            'unit_id',
        ));

        setOffset(response?.data?.next_offset);
    }, [api, configId, paramObject, workTicketId]);

    useEffect(() => {
        if (api && configId && workTicketId) {
            setUnits([]);
            setOffset(null);
            fetchUnits(0);
        }
    }, [api, workTicketId, configId, fetchUnits]);

    const lastRowRef = usePagination(offset, fetchUnits);

    const updateUnitsState = useCallback((prevState, unit, partUnits) => {
        const unitId = unit?.unit_id;
        const existing = _.find(
            prevState,
            {unit_id: unitId},
        );

        const newUnits = existing
            ? _.map(prevState, (prev) =>
                unitId === prev.unit_id
                    ? unit
                    : prev,
            )
            : !partUnits
                ? [unit, ...prevState]
                : [...prevState];

        const resourceFilter = paramObject?.resource_id;
        const stageFilter = paramObject?.stage;
        const assembledFilter = paramObject?.has_assembled_into_unit_id;

        return _.chain(newUnits)
            .filter((unit) => resourceFilter
                ? unit?.resource?.resource_id === resourceFilter
                : true)
            .filter((unit) => stageFilter
                ? _.includes(_.split(stageFilter, ','), unit?.stage)
                : true)
            .filter((unit) => {
                switch (assembledFilter) {
                    case '0':
                        return !unit.assembled_into_unit_id;
                    case '1':
                        return !!unit.assembled_into_unit_id;
                    default:
                        return true;
                }
            })
            .value();
    }, [paramObject]);

    const updateAllUnits = useCallback((unit) => {
        setUnits((prevState) => (
            updateUnitsState(prevState, unit, true)
        ));
    }, [updateUnitsState]);

    const dropdownItems = (aside, unit) => {
        return <>
            {!aside
                ? <Dropdown.Item
                    onClick={() => openAside(unit)}
                >
                    {'View Unit Details'}
                </Dropdown.Item>
                : null}
            <Dropdown.Item
                onClick={() =>
                    openEditUnit(unit)}
            >
                {'Edit Unit'}
            </Dropdown.Item>
            {!_.isNil(unit?.serial_number)
            && _.isNil(unit?.kit)
                ? <Dropdown.Item
                    onClick={() =>
                        openAddRemoveComp(unit)}
                >
                    {'Add/Remove Components'}
                </Dropdown.Item>
                : null
            }
            {_.isNil(unit?.assembled_into_unit_id)
                && _.isNil(unit?.kit)
                && (!unit?.serialized || unit.serial_number)
                && (<Dropdown.Item
                    onClick={() =>
                        openAddToAssembly(unit)}
                >
                    {'Add to Assembly'}
                </Dropdown.Item>)}
            {!_.isNil(unit?.assembled_into_unit_id)
                && _.isNil(unit?.kit)
                && (!unit?.serialized || unit.serial_number)
                && (<Dropdown.Item
                    onClick={() =>
                        openAddToAssembly(unit)}
                >
                    {'Remove From Assembly'}
                </Dropdown.Item>)}
            {(_.isNil(unit?.kit) && _.isNil(unit?.assembled_into_unit_id)
                && !unit?.serialized)
                || (!_.isNil(unit?.serial_number) && unit?.serialized
                    && _.isNil(unit?.kit))
                ? <Dropdown.Item
                    onClick={() => openAddToKit(unit)}>
                    {'Add to Kit'}
                </Dropdown.Item>
                : !_.isNil(unit?.kit) && _.isNil(unit?.assembled_into_unit_id)
                    ? <Dropdown.Item
                        onClick={() => openAddToKit(unit)}>
                        {'Remove from Kit'}
                    </Dropdown.Item>
                    : null}
        </>;
    };

    const openAddRemoveComp = useCallback((unit) => {
        setAsideChildren(<WorkTicketAddRemoveComponents
            unitId={unit?.unit_id}
            unitData={unit}
            unitsArray={units}
            updateAllUnits={updateAllUnits}
            updateUnitsState={updateUnitsState}
            workTicketId={workTicketId}
        />);
    }, [
        units,
        updateAllUnits,
        updateUnitsState,
        setAsideChildren,
        workTicketId,
    ]);

    const openAddToAssembly = useCallback((unit) => {
        setAsideChildren(<WorkTicketAddToAssembly
            unit={unit}
            unitsArray={units}
            updateAllUnits={updateAllUnits}
            updateUnitsState={updateUnitsState}
            workTicketId={workTicketId}
        />);
    }, [
        units,
        updateAllUnits,
        updateUnitsState,
        setAsideChildren,
        workTicketId,
    ]);

    const openAddToKit = useCallback((unit) => {
        setAsideChildren(<WorkTicketAddToKit
            unit={unit}
            updateAllUnits={updateAllUnits}
        />);
    }, [
        setAsideChildren,
        updateAllUnits,
    ]);

    const openEditUnit = useCallback((unit) => {
        setAsideChildren(
            <UnitEdit
                unit={unit}
                updateAllUnits={updateAllUnits}
            />);
    }, [setAsideChildren, updateAllUnits]);

    const openAside = (unit) => {
        setAsideChildren(
            <WorkTicketUnitAside
                unit={unit}
                dropdownItems={dropdownItems}
            />,
        );
    };

    const openFilter = useCallback(() => {
        setAsideChildren(<UnitFilter/>);
    }, [setAsideChildren]);

    return <>
        <PageHeader
            title={'Units'}
            actionButtons={<PageActionFilter
                onClick={openFilter}
                filterParams={paramObject}
                defaultFilterParams={null}
            />}
        >
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/')}>
                    {userFacilityLabel}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate({
                    pathname: '/work-tickets',
                    search: defaultSearchParams.workTicket,
                })}>
                    {'Work'}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate(
                    `/work-tickets/${workTicketId}/bom`,
                )}>
                    {`${units[0]?.work_ticket?.work_ticket_number ?? '…'}`}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {units[0]?.sku ?? '…'}
                </Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} md={2} xl={2}>
                    {'Serial Number'}
                </TableCol>
                <TableCol xs={4} md={2} xl={2}>
                    {'Stage'}
                </TableCol>
                <TableCol xs={3} md={2} xl={2} className={'d-none d-md-block'}>
                    {'Resource'}
                </TableCol>
                <TableCol xs={1} md={2} xl={2} className={'d-none d-md-block'}>
                    {'Assembled'}
                </TableCol>
                <TableCol xs={1} md={2} xl={1} className={'d-none d-md-block'}>
                    {'Kit'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {units?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Units found'}
                    </TableCol>
                </TableRow>
                : _.map(units, (unit) =>
                    <TableRow key={unit.unit_id}>
                        <TableCol xs={4} md={2} xl={2}>
                            {unit?.serial_number ?? 'N/A'}
                        </TableCol>
                        <TableCol xs={4} md={2} xl={2}>
                            {unit.stage}
                        </TableCol>
                        <TableCol xs={3} md={2} xl={2}
                            className={'d-none d-md-block'}>
                            {unit.resource?.label ?? 'N/A'}
                        </TableCol>
                        <TableCol md={2} xl={2} className={'d-none d-md-block'}>
                            <Form.Check
                                className={'ps-xl-4 ps-md-4'}
                                checked={!!unit?.assembled_into_unit_id}
                                readOnly={true}
                            />
                        </TableCol>
                        <TableCol md={2} xl={2} className={'d-none d-md-block'}>
                            {unit?.kit?.label ?? 'N/A'}
                        </TableCol>
                        <TableColActions xs={2} md={2} xl={2}>
                            <Dropdown className={'ms-3'}>
                                <Dropdown.Toggle as={DropdownToggle}/>
                                <Dropdown.Menu>
                                    {dropdownItems(false, unit)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </TableColActions>
                    </TableRow>,
                )}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default WorkTicketUnitsList;
