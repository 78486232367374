import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import styled from 'styled-components';
import _ from 'lodash';
import {defaultSearchParams} from '^config/defaultSearchParams';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import assetCaptureIcon from '^assets/images/assetCapture.svg';
import customerIcon from '^assets/images/customer.svg';
import extensionIcon from '^assets/images/extension.svg';
import facilityIcon from '^assets/images/facility.svg';
import homeIcon from '^assets/images/home.svg';
import packingIcon from '^assets/images/packing.svg';
import partIcon from '^assets/images/part.svg';
import resourceIcon from '^assets/images/resource.svg';
import settingIcon from '^assets/images/setting.svg';
import stagedWorkTicketIcon from '^assets/images/stagedWorkTicket.svg';
import storageLocationIcon from '^assets/images/storageLocation.svg';
import userIcon from '^assets/images/user.svg';
import workTicketIcon from '^assets/images/workTicket.svg';
import {CloseButton, Form} from 'react-bootstrap';

const NavImg = styled.img.attrs(() => ({
    className: 'my-2 me-2',
}))`
  width: 1.5rem;
  height: 1.5rem;
`;

const NavItem = styled.div.attrs(() => ({
    className: _.join([
        'd-flex',
        'align-items-center',
        'text-primary',
        'text-uppercase',
    ], ' '),
    role: 'button',
}))`
  letter-spacing: 0.5px;
`;

const globalNavLinks = {
    'Facilities': {
        icon: facilityIcon,
        path: {
            pathname: '/facilities',
            search: defaultSearchParams.facility,
        },
    },
    'Users': {
        icon: userIcon,
        path: '/users',
    },
    'Customers': {
        icon: customerIcon,
        path: '/customers',
    },
    'Parts': {
        icon: partIcon,
        path: '/parts',
    },
    'Extensions': {
        icon: extensionIcon,
        path: '/manage-extensions',
    },
    'Settings': {
        icon: settingIcon,
        path: '/settings',
    },
};

const facilityNavLinks = {
    'Home': {
        icon: homeIcon,
        path: '/',
    },
    'Resources': {
        icon: resourceIcon,
        path: {
            pathname: '/resources',
            search: defaultSearchParams.resource,
        },
    },
    'Storage Locations': {
        icon: storageLocationIcon,
        path: {
            pathname: '/storage-locations',
            search: defaultSearchParams.storageLocation,
        },
    },
    'Staged Work': {
        icon: stagedWorkTicketIcon,
        path: {
            pathname: '/staged-work-tickets',
            search: defaultSearchParams.stagedWorkTicket,
        },
    },
    'Work': {
        icon: workTicketIcon,
        path: {
            pathname: '/work-tickets',
            search: defaultSearchParams.workTicket,
        },
    },
    'Asset Capture': {
        icon: assetCaptureIcon,
        path: '/asset-capture',
    },
    'Packing': {
        icon: packingIcon,
        path: '/packing',
    },
    'Extensions': {
        icon: extensionIcon,
        path: '/extensions',
    },
};

const LeftNav = () => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const {setLeftNavOpen} = useContext(AppContext);
    const [userFacility, setUserFacility] = useLocalStorage('facility_id', '');
    const [
        userFacilityLabel,
        setUserFacilityLabel,
    ] = useLocalStorage('facility_label', '');
    const [facilities, setFacilities] = useState([]);
    const [facilitiesLoading, setFacilitiesLoading] = useState(true);
    const [showGlobalRoutes, setShowGlobalRoutes] = useState(false);

    useEffect(() => {
        const fetchFacilities = async () => {
            setFacilitiesLoading(true);

            const response = await api.get('/facilities', {
                params: {active: 1},
            });

            setFacilitiesLoading(false);

            if (isResponseError(response)) {
                return;
            }

            setFacilities(response?.data?.results);
        };

        if (api) {
            fetchFacilities();
        }
    }, [api]);

    const updateDefaultFacility = useCallback(async (e) => {
        const facilityId = e.target.value;

        if (facilityId === 'GLOBAL') {
            setShowGlobalRoutes(true);
            return;
        }

        setShowGlobalRoutes(false);
        setUserFacility(facilityId);
        const facility = _.find(facilities, {facility_id: facilityId});
        setUserFacilityLabel(facility?.label ?? '');

        await api.patch('/me', {
            default_facility: {facility_id: facilityId}},
        );
    }, [
        api,
        facilities,
        setUserFacility,
        setUserFacilityLabel,
    ]);

    const facilityOptions = _.uniqBy([
        ..._.map(facilities, (facility) => ({
            facility_id: facility.facility_id,
            label: facility.label,
        })),
        ...userFacility && userFacilityLabel
            ? [{
                facility_id: userFacility,
                label: userFacilityLabel,
            }]
            : [],
    ], 'facility_id');

    return <div className={'h-100 p-2'}>
        <CloseButton
            className={'float-end'}
            onClick={() => setLeftNavOpen(false)}
        />
        <Form.Text>{'Facility'}</Form.Text>
        <Form.Select
            disabled={facilitiesLoading}
            value={showGlobalRoutes
                ? 'GLOBAL'
                : userFacility}
            onChange={updateDefaultFacility}
        >
            <option value={''} disabled={true}>
                {'Select a Facility'}
            </option>
            <option value={'GLOBAL'}>
                {'Global'}
            </option>
            <hr/>
            {_.map(facilityOptions, (facility) => <option
                key={facility.facility_id}
                value={facility.facility_id}
            >
                {facility.label}
            </option>)}
        </Form.Select>
        <hr/>
        <nav>
            {_.map(
                showGlobalRoutes
                    ? globalNavLinks
                    : facilityNavLinks,
                ({icon, path}, key) => <NavItem
                    key={key}
                    onClick={() => {
                        setLeftNavOpen(false);
                        navigate(path);
                    }}
                >
                    <NavImg src={icon} alt={key}/>
                    {key}
                </NavItem>,
            )}
        </nav>
        <br/>
    </div>;
};

export default LeftNav;
