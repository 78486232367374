import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import _ from 'lodash';
import CustomAccordion from '^common/customAccordion';
import {Accordion, Form} from 'react-bootstrap';
import {FormSelect, AsyncFormSelect} from '^common/formSelect';
import AddToCard from '^pages/workTicket/commonAddtoAssemblyKitting/addToCard';
import {getDescendants, toggleAccordion}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';

const AddToAssemblyKittingFilter = ({
    asideData,
    type,
    hide,
    entityList,
    setEntityList,
    barcodeUnits,
    filters,
    setters,
    partConfigOptions,
    descendantTrees,
    handleSubmit,
    setBarcodeUnits,
    setDescendantTrees,
    workTicketId,
}) => {
    const api = useContext(ApiContext);
    const skuInputRef = useRef(null);

    const [options, setOptions] = useState([]);
    const [partConfigInput, setPartConfigInput] = useState('');

    const {
        serializedFilter = null,
        partConfigFilter = null,
        kitFilter = null,
    } = filters;

    const {
        setComponentsKey = null,
        setKitsKey = null,
        setSerializedFilter = null,
        setPartConfigFilter = null,
        setKitFilter = null,
    } = setters;

    const setupUnitsForFilter = useCallback(
        (units, stage, filterLabel) => {
            let unitsListCloned = _.cloneDeep(units);
            if (stage !== 'Rolled-Back') {
                unitsListCloned = _.filter(unitsListCloned,
                    (unit) => unit.stage !== 'Rolled-Back');
            }

            const serializedUnits = _.filter(unitsListCloned,
                (unit) => !_.isNil(unit.serial_number));
            const barcodeUnits = _.filter(unitsListCloned,
                (unit) => unit.barcode !== null);

            setBarcodeUnits((prevState) => {
                const existingUnits = prevState[filterLabel] || [];

                const uniqueBarcodeUnits = _.filter(barcodeUnits, (
                    (unit) =>
                        // eslint-disable-next-line max-len
                        !existingUnits.some((existingUnit) => existingUnit.unit_id === unit.unit_id)
                ));

                return {
                    ...prevState,
                    [filterLabel]: [
                        ...existingUnits,
                        ...uniqueBarcodeUnits,
                    ],
                };
            });
            setEntityList((prevState) => {
                const existingUnits = prevState[filterLabel] || [];

                const uniqueSerializedUnits = _.filter(serializedUnits, (
                    (unit) =>
                        // eslint-disable-next-line max-len
                        !existingUnits.some((existingUnit) => existingUnit.unit_id === unit.unit_id)
                ));

                return {
                    ...prevState,
                    [filterLabel]: [
                        ...existingUnits,
                        ...uniqueSerializedUnits,
                    ],
                };
            });
        }, [setBarcodeUnits, setEntityList]);

    const searchForSerializedUnits = useCallback(async (serial) => {
        if (!api || _.isNil(partConfigFilter)) return [];

        const response = await api.get('/units', {
            params: {
                work_ticket_id: workTicketId,
                config_id: partConfigFilter.value.config_id,
                ...(serial ? {serial_number: serial} : {}),
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const unitsList = response?.data?.results ?? [];

        setupUnitsForFilter(
            unitsList,
            asideData.stage,
            partConfigFilter.label,
        );

        return _.map(unitsList,
            (unit) => ({label: unit.serial_number, value: unit}));
    }, [
        partConfigFilter,
        api,
        asideData,
        setupUnitsForFilter,
        workTicketId,
    ]);

    const getSerializedDescendantTree = useCallback(async (unitId) => {
        if (!api) return;

        const response = await api.get(`/units/${unitId}/assembly`);

        if (isResponseError(response)) {
            return;
        }

        const unitsList = response?.data ?? [];

        const descendants = getDescendants(unitsList, unitId);
        const unitIndex = _.findIndex(unitsList, {'unit_id': unitId});

        setDescendantTrees((prevState) => ({
            ...prevState,
            available: {
                ...prevState?.available,
                [unitId]: {
                    children: descendants,
                    name: unitIndex >= 0
                        ? unitsList[unitIndex]?.serial_number
                        : null,
                },
            },
        }));
    }, [api, setDescendantTrees]);

    useEffect(() => {
        if (partConfigOptions) {
            setTimeout(() => {
                skuInputRef?.current?.focus?.();
            }, 100);
        }
    }, [partConfigOptions]);

    useEffect(() => {
        if (partConfigFilter && entityList) {
            let filteredUnits;
            if (_.includes(partConfigFilter?.label, '(Serialized)')) {
                filteredUnits = entityList[partConfigFilter.label];
            } else if (!_.isNil(barcodeUnits)) {
                filteredUnits = barcodeUnits[partConfigFilter?.label];
            }
            setOptions(_.map(filteredUnits, (unit) => ({
                label: unit.serial_number,
                value: {...unit},
            })));
        }
    }, [entityList, partConfigFilter, barcodeUnits]);

    useEffect(() => {
        if (type === 'kit' && entityList) {
            setOptions(_.map(entityList, (kit) => ({
                label: kit.label,
                value: {...kit},
            })));
        }
    }, [entityList, type]);

    const filteredPartConfigOptions = _.filter(
        partConfigOptions,
        ({label}) => {
            const partInputRegExp = _.escapeRegExp(partConfigInput);
            const regex = new RegExp(`^${partInputRegExp}(?:$| .+)`);

            return partConfigInput
                ? regex.test(label)
                : false;
        },
    );

    return !hide && <CustomAccordion key={'Filter'}
        defaultActiveKey={'0'}>
        <Accordion.Item eventKey={'0'}>
            <Accordion.Header>{'Filter'}</Accordion.Header>
            <Accordion.Body>
                {type === 'assembly'
                    ? <Form.Group>
                        <Form.Text>{'SKU'}</Form.Text>
                        <FormSelect
                            ref={skuInputRef}
                            isClearable={true}
                            backspaceRemovesValue={true}
                            value={_.find(partConfigOptions,
                                {label: partConfigFilter?.label})
                                ?? null}
                            options={filteredPartConfigOptions}
                            onInputChange={setPartConfigInput}
                            noOptionsMessage={({inputValue}) => inputValue
                                ? `SKU "${inputValue}" not found`
                                : 'Enter SKU'}
                            onChange={(e) => {
                                setPartConfigFilter(e ?? null);
                                setSerializedFilter(null);
                            }}
                        />
                        {partConfigFilter
                            && <>
                                <Form.Text>{'Serial Number'}</Form.Text>
                                <AsyncFormSelect
                                    isClearable={true}
                                    loadOptions={searchForSerializedUnits}
                                    backspaceRemovesValue={true}
                                    value={_.find(options, (option) =>
                                        option?.value?.serial_number
                                            === serializedFilter?.serial_number)
                                        ?? null
                                    }
                                    onChange={(e) => {
                                        if (e?.value?.has_assembly_components) {
                                            getSerializedDescendantTree(
                                                e.value.unit_id,
                                            );
                                        }
                                        setSerializedFilter(e?.value ?? null);
                                        toggleAccordion(setComponentsKey, null);
                                    }}
                                />
                            </>
                        }</Form.Group>
                    : <Form.Group>
                        <Form.Text>{'Kit'}</Form.Text>
                        <FormSelect
                            isClearable={true}
                            backspaceRemovesValue={true}
                            value={_.find(options,
                                {value: kitFilter})
                                ?? null}
                            options={options}
                            onChange={(e) => {
                                setKitFilter(e?.value ?? null);
                                toggleAccordion(setKitsKey, null);
                            }}
                        />
                    </Form.Group>}
                {!_.isNil(serializedFilter)
                    && <AddToCard
                        assemble={true}
                        unit={serializedFilter}
                        descendantTrees={descendantTrees}
                        handleSubmit={handleSubmit}
                    />
                }
                {!_.isNil(kitFilter)
                    && <AddToCard
                        assemble={true}
                        kit={kitFilter}
                        handleSubmit={handleSubmit}
                    />
                }
            </Accordion.Body>
        </Accordion.Item>
    </CustomAccordion>;
};

export default AddToAssemblyKittingFilter;
