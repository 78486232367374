import React, {useContext, useEffect, useState, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import unitIcon from '^assets/images/unit.svg';
import {DropdownToggle} from '^common/dropdownToggle';
import {PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon, TableIconText} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import WorkTicketKitCreateEdit
    from '^pages/workTicket/kitting/workTicketKitCreateEdit';
import WorkTicketKitting from '^pages/workTicket/kitting/workTicketKitting';
import {Dropdown, Breadcrumb, Spinner} from 'react-bootstrap';
import WorkTicketKitAside from '^pages/workTicket/aside/workTicketKitAside';
import {defaultSearchParams} from '^config/defaultSearchParams';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';

const WorkTicketKitsList = () => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const {work_ticket_id: workTicketId} = useParams();
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    const [workTicket, setWorkTicket] = useState(null);
    const [kits, setKits] = useState([]);
    const [loading, setLoading] = useState(true);
    const {setAsideChildren} = useContext(AppContext);
    const [offset, setOffset] = useState(null);

    const workTicketGroupId = workTicket
        ?.work_ticket_group?.work_ticket_group_id;

    const fetchKits = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/kits', {
            params: {
                offset,
                limit,
                work_ticket_group_id: workTicketGroupId,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }
        const results = response?.data?.results;

        setKits((prevState) => _.uniqBy(
            [...prevState, ...results],
            'kit_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, workTicketGroupId]);

    useEffect(() => {
        const fetchWorkTicket = async () => {
            const response = await api.get(`/work-tickets/${workTicketId}`);

            if (isResponseError(response)) {
                return;
            }

            setWorkTicket(response?.data);
        };

        if (!api) {
            return;
        }

        if (!workTicketId) {
            return;
        }

        fetchWorkTicket();
    }, [api, workTicketId, fetchKits]);

    useEffect(() => {
        if (!api) {
            return;
        }

        if (!workTicketGroupId) {
            return;
        }

        setKits([]);
        setOffset(null);
        fetchKits(0);
    }, [api, fetchKits, workTicketGroupId]);

    const lastRowRef = usePagination(offset, fetchKits);

    const updateKit = useCallback((kit) => {
        setKits((prevState) => {
            const kitId = kit?.kit_id;
            const existing = _.find(
                prevState,
                {kit_id: kitId},
            );

            return existing
                ? _.map(prevState, (prev) =>
                    kitId === prev.kit_id
                        ? kit
                        : prev,
                ) : [kit, ...prevState];
        });
    }, []);

    const dropdownItems = (aside, kit) => {
        return <>
            {!aside
                ? <Dropdown.Item
                    onClick={() => openAside(kit)}
                >{'View Kit Details'}</Dropdown.Item>
                : null}
            <Dropdown.Item
                onClick={() =>
                    openCreateEditKit(kit, workTicket)}
            >
                {'Edit Kit'}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => openKitting(kit)}
            >
                {'Add/Remove Contents'}
            </Dropdown.Item>
        </>;
    };

    const openCreateEditKit = useCallback((kit, workTicket) => {
        setAsideChildren(
            <WorkTicketKitCreateEdit
                kit={kit}
                workTicket={workTicket}
                updateKit={updateKit}
            />,
        );
    }, [setAsideChildren, updateKit]);

    const openKitting = useCallback((kit) => {
        setAsideChildren(
            <WorkTicketKitting
                kit={kit}
                updateKit={updateKit}
                workTicketId={workTicketId}
            />);
    }, [setAsideChildren, updateKit, workTicketId]);

    const openAside = (kit) => {
        setAsideChildren(
            <WorkTicketKitAside
                kit={kit}
                dropdownItems={dropdownItems}/>,
        );
    };

    return <>
        <PageHeader
            title={'Kits'}
            actionButtons={<PageActionIcon
                src={addIcon}
                alt={'Create'}
                onClick={() => openCreateEditKit(null, workTicket)}
            />}
        >
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/')}>
                    {userFacilityLabel}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate({
                    pathname: '/work-tickets',
                    search: defaultSearchParams.workTicket,
                })}>
                    {'Work'}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('/work-tickets/'
                    + `${workTicketId}`
                    + '/kits')}
                >{workTicket?.work_ticket_number ?? '…'}</Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} md={3} xl={2}>
                    {'Label'}
                </TableCol>
                <TableCol xs={4} md={3} xl={2}>
                    {'Stage'}
                </TableCol>
                <TableCol xs={3} xl={2} className={'d-none d-md-block'}>
                    {'Resource'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {kits?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Kits found'}
                    </TableCol>
                </TableRow>
                : _.map(kits, (kit) =>
                    <TableRow key={kit.kit_id}>
                        <TableCol xs={4} md={3} xl={2}>
                            {kit?.label}
                        </TableCol>
                        <TableCol xs={4} md={3} xl={2}>
                            {kit?.stage}
                        </TableCol>
                        <TableCol xs={3} xl={2} className={'d-none d-md-block'}>
                            {kit?.resource?.label}
                        </TableCol>
                        <TableColActions xs={4} md={3} xl={6}>
                            <TableIcon
                                src={unitIcon}
                                alt={'Parts'}
                                width={'25'}
                                height={'25'}
                                onClick={() => openKitting(kit)}
                            />
                            <TableIconText $characters={3}>
                                {kit?.unit_count}
                            </TableIconText>
                            <Dropdown>
                                <Dropdown.Toggle as={DropdownToggle}/>
                                <Dropdown.Menu>
                                    {dropdownItems(false, kit)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </TableColActions>
                    </TableRow>,
                )}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default WorkTicketKitsList;
