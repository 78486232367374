import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {useLocalStorage} from 'usehooks-ts';
import {isResponseError} from '^utilities/isResponseError';
import {usePagination} from '^utilities/usePagination';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {limit} from '^config/pagination';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import refreshIcon from '^assets/images/refresh.svg';
import {PageActionFilter, PageActionIcon} from '^common/pageAction';
import {Col, Container, Row} from 'react-bootstrap';
import WorkTicketTable from '^pages/workTicket/workTicketTable';
import MyAssignmentsFilter from './myAssignmentsFilter';

const MyAssignmentsList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [userFacility] = useLocalStorage('facility_id', '');
    const [userId, setUserId] = useState(null);
    const [assignedWorkTickets, setAssignedWorkTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const [
        filterParams,
        setFilterParams,
    ] = useState(defaultSearchParamsObj.workTicket);

    useEffect(() => {
        const getMe = async () => {
            const response = await api.get('/me');

            if (isResponseError(response)) {
                return;
            }

            const user = response?.data;
            setUserId(user?.user_id);
        };

        if (api) {
            getMe();
        }
    }, [api]);

    const customerFilter = filterParams?.customer?.value;
    const statusFilter = filterParams?.status;
    const minStageFilter = filterParams?.minStage;
    const maxStageFilter = filterParams?.maxStage;
    const targetMetricsStatusFilter = filterParams?.targetMetricsStatus;
    const inStorageFilter = filterParams?.inStorage?.value;
    const outStorageFilter = filterParams?.outStorage?.value;
    const priorityFilter = filterParams?.priority;
    const projectFilter = filterParams?.project;
    const poolFilter = filterParams?.pool;
    const shipDateFilter = filterParams?.shipDate;
    const statusUpdatedAfterFilter = filterParams?.statusUpdatedAfter;

    const fetchAssignedWorkTickets = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get(`/users/${userId}/work-tickets`, {
            params: {
                facility_id: userFacility,
                ...customerFilter
                    ? {customer_id: customerFilter}
                    : {},
                ..._.size(statusFilter)
                    ? {status: _.join(statusFilter, ',')}
                    : {},
                ...minStageFilter
                    ? {min_stage: minStageFilter}
                    : {},
                ...maxStageFilter
                    ? {max_stage: maxStageFilter}
                    : {},
                ..._.size(targetMetricsStatusFilter)
                    ? {target_metrics_overall_status: _.join(
                        targetMetricsStatusFilter,
                        ',',
                    )}
                    : {},
                ...inStorageFilter
                    ? {inbound_storage_location_id: inStorageFilter}
                    : {},
                ...outStorageFilter
                    ? {outbound_storage_location_id: outStorageFilter}
                    : {},
                ...priorityFilter
                    ? {is_priority: priorityFilter}
                    : {},
                ...projectFilter
                    ? {is_project: projectFilter}
                    : {},
                ..._.size(poolFilter)
                    ? {pool: _.join(poolFilter, ',')}
                    : {},
                ...shipDateFilter
                    ? {ship_date: shipDateFilter}
                    : {},
                ...statusUpdatedAfterFilter
                    ? {status_updated_after: statusUpdatedAfterFilter}
                    : {},
                offset,
                limit,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setAssignedWorkTickets((prevState) => _.uniqBy(
            [...prevState, ...results],
            'work_ticket_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [
        api,
        customerFilter,
        inStorageFilter,
        maxStageFilter,
        minStageFilter,
        outStorageFilter,
        poolFilter,
        priorityFilter,
        projectFilter,
        shipDateFilter,
        statusFilter,
        targetMetricsStatusFilter,
        statusUpdatedAfterFilter,
        userFacility,
        userId,
    ]);

    useEffect(() => {
        if (api && userFacility && userId) {
            setAssignedWorkTickets([]);
            setOffset(null);
            fetchAssignedWorkTickets(0);
        }
    }, [api, userId, fetchAssignedWorkTickets, filterParams, userFacility]);

    const updateWorkTicket = useCallback((workTicket) => {
        setAssignedWorkTickets((prevState) => {
            const workTicketId = workTicket?.work_ticket_id;

            const newWorkTickets = _.map(prevState, (prev) =>
                workTicketId === prev.work_ticket_id
                    ? workTicket
                    : prev);

            return _.chain(newWorkTickets)
                .filter((workTicket) => customerFilter
                    ? workTicket?.customer?.customer_id === customerFilter
                    : true)
                .filter((workTicket) => _.size(statusFilter)
                    ? _.includes(statusFilter, workTicket?.status)
                    : true)
                .filter((workTicket) => targetMetricsStatusFilter
                    ? _.includes(
                        targetMetricsStatusFilter,
                        workTicket?.target_metrics?.overall_status,
                    )
                    : true)
                .filter((workTicket) => inStorageFilter
                    ? workTicket?.inbound_storage_location
                        ?.storage_location_id === inStorageFilter
                    : true)
                .filter((workTicket) => outStorageFilter
                    ? workTicket?.outbound_storage_location
                        ?.storage_location_id === outStorageFilter
                    : true)
                .filter((workTicket) => {
                    switch (priorityFilter) {
                        case '0':
                            return workTicket.is_priority === false;
                        case '1':
                            return workTicket.is_priority === true;
                        default:
                            return true;
                    }
                })
                .filter((workTicket) => {
                    switch (projectFilter) {
                        case '0':
                            return workTicket.is_project === false;
                        case '1':
                            return workTicket.is_project === true;
                        default:
                            return true;
                    }
                })
                .filter((workTicket) => poolFilter
                    ? _.includes(poolFilter, workTicket?.pool)
                    : true)
                .filter((workTicket) => shipDateFilter
                    ? workTicket?.ship_date === shipDateFilter
                    : true)
                .value();
        });
    }, [
        customerFilter,
        inStorageFilter,
        outStorageFilter,
        poolFilter,
        priorityFilter,
        projectFilter,
        shipDateFilter,
        statusFilter,
        targetMetricsStatusFilter,
    ]);

    const lastRowRef = usePagination(offset, fetchAssignedWorkTickets);

    const openFilter = useCallback(() => {
        setAsideChildren(<MyAssignmentsFilter
            filterParams={filterParams}
            setFilterParams={setFilterParams}
        />);
    }, [filterParams, setAsideChildren]);

    return <>
        <Container fluid={true}>
            <Row>
                <Col className={'text-end'}>
                    <PageActionIcon
                        src={refreshIcon}
                        alt={'Refresh'}
                        disabled={loading}
                        onClick={() => {
                            setAssignedWorkTickets([]);
                            setOffset(null);
                            fetchAssignedWorkTickets(0);
                        }}
                    />
                    <PageActionFilter
                        onClick={openFilter}
                        filterParams={filterParams}
                        defaultFilterParams={defaultSearchParamsObj.workTicket}
                    />
                </Col>
            </Row>
        </Container>
        <WorkTicketTable
            updateWorkTicket={updateWorkTicket}
            workTickets={assignedWorkTickets}
            loading={loading}
            offset={offset}
            lastRowRef={lastRowRef}
        />
    </>;
};

export default MyAssignmentsList;
